/* eslint-disable max-len */
import React from "react";

const IntuChargeLogoDark: React.FC = () => (
  <svg width="179" height="45" viewBox="0 0 246 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.9237 55.5849C40.4024 55.5849 35.322 53.3918 31.6822 49.0055C28.0424 44.6192 26.2226 37.8633 26.2226 28.7378C26.2226 19.5619 28.0424 12.806 31.6822 8.47009C35.322 4.08379 40.4024 1.89065 46.9237 1.89065C50.5634 1.89065 53.824 2.59649 56.7055 4.00817C59.587 5.41985 61.8618 7.613 63.5301 10.5876C65.1983 13.5622 66.0324 17.3939 66.0324 22.0827H56.0989C56.0989 18.0494 55.2395 15.0747 53.5207 13.1589C51.8525 11.1926 49.6535 10.2095 46.9237 10.2095C43.3345 10.2095 40.6552 11.6968 38.8859 14.6714C37.1166 17.5956 36.2319 21.6542 36.2319 26.8471V30.6284C36.2319 35.8214 37.1418 39.9052 38.9617 42.8798C40.7816 45.804 43.5367 47.2661 47.227 47.2661C50.0579 47.2661 52.3075 46.3082 53.9757 44.3923C55.6945 42.4261 56.5539 39.4514 56.5539 35.4685H66.0324C66.0324 40.006 65.1983 43.7621 63.5301 46.7367C61.8618 49.7113 59.587 51.9297 56.7055 53.3918C53.8746 54.8539 50.614 55.5849 46.9237 55.5849Z"
      fill="#060F17"
    />
    <path
      d="M74.8164 54.6774V0H83.9158V19.1333H84.2191C85.0785 17.8729 86.317 16.7133 87.9347 15.6545C89.5523 14.5454 91.5239 13.9908 93.8493 13.9908C96.9329 13.9908 99.4606 14.9487 101.432 16.8645C103.454 18.7804 104.465 22.0827 104.465 26.7715V54.6774H95.3658V29.2672C95.3658 26.6959 94.8856 24.8809 93.9251 23.8221C93.0152 22.7634 91.7513 22.234 90.1337 22.234C88.3643 22.234 86.8731 23.0154 85.6598 24.5784C84.4971 26.0909 83.9158 27.9563 83.9158 30.1747V54.6774H74.8164Z"
      fill="#060F17"
    />
    <path
      d="M121.831 55.5849C120.921 55.5849 119.885 55.4337 118.722 55.1312C117.56 54.8287 116.448 54.2741 115.386 53.4674C114.375 52.6607 113.516 51.5264 112.808 50.0643C112.151 48.6022 111.822 46.7115 111.822 44.3923C111.822 40.8127 112.656 38.0146 114.324 35.9979C116.043 33.9812 118.394 32.5695 121.376 31.7628C124.359 30.9057 127.771 30.4772 131.613 30.4772V26.3178C131.613 24.9565 131.36 23.7969 130.855 22.839C130.349 21.8811 129.212 21.4021 127.443 21.4021C125.572 21.4021 124.308 21.8558 123.651 22.7634C122.994 23.6709 122.666 24.6036 122.666 25.5615V26.6959H113.794C113.743 26.4438 113.718 26.1917 113.718 25.9396C113.718 25.6876 113.718 25.385 113.718 25.0321C113.718 21.7046 114.931 19.0325 117.358 17.0158C119.835 14.9991 123.12 13.9908 127.215 13.9908C131.866 13.9908 135.278 15.0243 137.452 17.0914C139.676 19.1081 140.788 21.9819 140.788 25.7128V45.2998C140.788 46.2578 141.016 46.9384 141.471 47.3417C141.926 47.7451 142.406 47.9467 142.912 47.9467H144.732V54.3749C144.226 54.627 143.544 54.8791 142.684 55.1312C141.825 55.4337 140.713 55.5849 139.348 55.5849C137.528 55.5849 136.011 55.106 134.798 54.1481C133.585 53.1901 132.751 51.9297 132.296 50.3668H131.917C130.804 51.9297 129.515 53.1901 128.049 54.1481C126.583 55.106 124.511 55.5849 121.831 55.5849ZM125.623 48.0224C127.089 48.0224 128.252 47.619 129.111 46.8124C129.97 46.0057 130.602 44.9469 131.007 43.6361C131.411 42.3252 131.613 40.8883 131.613 39.3254V37.0566C129.844 37.0566 128.15 37.2583 126.533 37.6616C124.966 38.0146 123.677 38.67 122.666 39.6279C121.654 40.5354 121.149 41.8463 121.149 43.5604C121.149 44.9217 121.503 46.0057 122.211 46.8124C122.918 47.619 124.056 48.0224 125.623 48.0224Z"
      fill="#060F17"
    />
    <path
      d="M151.639 54.6774V14.8983H159.222L160.284 20.7215H160.739C161.295 19.0577 162.078 17.52 163.089 16.1083C164.1 14.6966 165.617 13.9908 167.639 13.9908C168.802 13.9908 169.712 14.0916 170.369 14.2933C171.077 14.4949 171.431 14.5958 171.431 14.5958V23.3684H168.17C166.856 23.3684 165.617 23.6457 164.454 24.2002C163.342 24.7548 162.432 25.6876 161.725 26.9984C161.067 28.3092 160.739 30.1495 160.739 32.5191V54.6774H151.639Z"
      fill="#060F17"
    />
    <path
      d="M231.896 55.5849C226.79 55.5849 222.898 53.9212 220.218 50.5936C217.59 47.2157 216.275 41.9471 216.275 34.7879C216.275 27.6286 217.59 22.3852 220.218 19.0577C222.898 15.6797 226.689 13.9908 231.593 13.9908C236.395 13.9908 239.984 15.6797 242.36 19.0577C244.787 22.3852 246 27.6286 246 34.7879V37.0566H225.754C225.804 40.5858 226.285 43.3336 227.195 45.2998C228.105 47.2157 229.672 48.1736 231.896 48.1736C233.463 48.1736 234.702 47.5182 235.612 46.2073C236.521 44.8461 236.976 42.9806 236.976 40.611H246C246 45.7032 244.66 49.4845 241.981 51.9549C239.302 54.3749 235.94 55.5849 231.896 55.5849ZM225.83 30.4772H236.446C236.446 27.6034 236.041 25.385 235.232 23.8221C234.474 22.2088 233.261 21.4021 231.593 21.4021C229.672 21.4021 228.256 22.2088 227.346 23.8221C226.487 25.385 225.981 27.6034 225.83 30.4772Z"
      fill="#060F17"
    />
    <path
      d="M1.32645 52.5863C0.941173 52.9718 0.748535 53.4859 0.748535 54.1124C0.748535 54.7388 0.941173 55.2368 1.32645 55.6223C1.71172 56.0079 2.20937 56.2006 2.83545 56.2006L20.6384 56.2006C21.2645 56.2006 21.7621 56.0079 22.1474 55.6223C22.5327 55.2368 22.7253 54.7388 22.7253 54.1124C22.7253 53.4859 22.5327 52.9879 22.1474 52.5863C21.7621 52.2008 21.2645 51.992 20.6384 51.992L2.83545 51.992C2.22543 51.992 1.71172 52.1847 1.32645 52.5863Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7896 11.3436C22.7896 15.9217 19.2579 19.6485 14.9075 19.6485L2.61076 19.6485C1.56731 19.6485 0.716492 18.7971 0.716492 17.753C0.716492 16.7089 1.56731 15.8575 2.61076 15.8575L14.9075 15.8575C17.171 15.8575 19.001 13.8335 19.001 11.3275C19.001 8.83766 17.1549 6.81363 14.9075 6.81363L2.61076 6.81363C1.56731 6.81363 0.716492 5.96226 0.716492 4.91812C0.716492 3.87398 1.56731 3.0226 2.61076 3.0226L14.9075 3.0226C19.2579 3.0226 22.7896 6.74938 22.7896 11.3436Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8217 23.7449C22.8217 28.323 19.2739 32.098 14.9075 32.098L2.61076 32.098C1.56731 32.098 0.716492 31.2466 0.716492 30.2025C0.716492 29.1583 1.56731 28.307 2.61076 28.307L4.92242 28.307L4.92242 27.1504C4.92242 25.9938 5.86956 25.046 7.02538 25.046C8.18121 25.046 9.12835 25.9938 9.12835 27.1504L9.12835 28.307L14.9075 28.307C17.1389 28.307 19.0331 26.1866 19.0331 23.7288C19.0331 23.0863 18.9047 22.4759 18.696 21.9136C19.8679 21.3835 20.9274 20.6125 21.8103 19.6487C22.4525 20.8695 22.8217 22.267 22.8217 23.7449Z"
      fill="#50C878"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.63064 33.7401L20.9274 33.7401C21.9708 33.7401 22.8216 34.5915 22.8216 35.6356C22.8216 36.6798 21.9708 37.5312 20.9274 37.5312L8.63064 37.5311C6.36714 37.5311 4.53708 39.5552 4.53708 42.045C4.53708 44.5349 6.38319 46.5589 8.63064 46.5589L20.9274 46.5589C21.9708 46.5589 22.8216 47.4103 22.8216 48.4544C22.8216 49.4986 21.9708 50.35 20.9274 50.35L8.63064 50.35C4.28023 50.35 0.748535 46.6232 0.748535 42.045C0.748535 37.4669 4.29628 33.7401 8.63064 33.7401Z"
      fill="#50C878"
    />
    <path
      d="M186.249 52.3128C186.249 57.1988 190.21 61.1604 195.096 61.1604C199.982 61.1604 203.943 57.1988 203.943 52.3128C203.943 47.4267 199.982 43.4651 195.096 43.4651C190.21 43.4651 186.249 47.4267 186.249 52.3128Z"
      fill="#50C878"
    />
    <path
      d="M194.828 43.466C204.6 43.466 212.522 35.5429 212.522 25.7708H194.828L194.828 43.466Z"
      fill="#50C878"
    />
    <path
      d="M177.134 25.771C177.134 35.5431 185.055 43.4663 194.828 43.4663L194.828 25.771H177.134Z"
      fill="#50C878"
    />
    <path
      d="M194.828 25.7719H212.522C212.522 15.9998 204.6 8.07662 194.828 8.07662L194.828 25.7719Z"
      fill="#50C878"
    />
    <path
      d="M177.134 25.7719H194.828L194.828 8.07662C185.055 8.07662 177.134 15.9998 177.134 25.7719Z"
      fill="#50C878"
    />
    <path
      d="M200.864 24.1671L195.435 36.8356C195.35 37.0241 195.204 37.1782 195.02 37.2725C194.836 37.3667 194.626 37.3954 194.423 37.3539C194.221 37.3123 194.039 37.203 193.907 37.044C193.775 36.8849 193.701 36.6857 193.698 36.479V28.335H189.173C189.024 28.3343 188.877 28.2966 188.745 28.2255C188.614 28.1543 188.502 28.0518 188.42 27.927C188.337 27.8022 188.287 27.6591 188.273 27.5102C188.259 27.3613 188.283 27.2114 188.341 27.0736L193.77 14.4052C193.852 14.2132 193.997 14.0553 194.182 13.9584C194.367 13.8615 194.58 13.8315 194.784 13.8734C194.989 13.9154 195.172 14.0267 195.304 14.1886C195.436 14.3505 195.508 14.553 195.507 14.7617V22.9057H200.032C200.181 22.9065 200.328 22.9441 200.46 23.0153C200.591 23.0865 200.703 23.189 200.785 23.3138C200.868 23.4386 200.918 23.5817 200.932 23.7306C200.946 23.8794 200.922 24.0294 200.864 24.1671Z"
      fill="white"
    />
  </svg>
);

export default IntuChargeLogoDark;
